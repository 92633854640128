<template>
  <modal :name="modalName" classes="clear_modal white_modal" :scrollable="true" height="auto" @before-open="beforeOpen">
    <div class="body person-edit-modal">
      <div class="source-data">
        <div class="bold">Source data</div>
        <div v-for="data in sourceData" class="source-data-item">
          <div class="label supplemental">{{ data.label }}:</div>
          <div>{{ data.value }}</div>
        </div>
        <div class="source-data-item" v-if="meta.source_urls && meta.source_urls.length">
          <div class="label supplemental">Source Url:</div>
          <div v-for="url in meta.source_urls">
            <a :href="url" target="_blank">{{ url }}</a>
          </div>
        </div>
      </div>
      <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
      <admin-person-edit-form
        v-else
        :form-initial-data="formInitialData"
        :form-loading="familyTreePersonUpdateLoadingState"
        :show-relatives-json-field="true"
        @save="saveClickHandler"
        @cancel="closeModal"
      />
    </div>
  </modal>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import AdminPersonEditForm from '@/components/common/forms/admin/AdminPersonEditForm';

export default {
  data() {
    const name = 'person-edit-modal';
    return {
      modalName: this.modifier ? `${name}-${this.modifier}` : name,
    };
  },
  props: {
    person: Object,
    meta: Object,
    modifier: {type: String, required: false},
  },
  computed: {
    ...mapGetters(['familyTreePersonOptionsState', 'familyTreePersonUpdateLoadingState']),
    loading() {
      return isEmpty(this.familyTreePersonOptionsState);
    },
    formInitialData() {
      return {...this.person, is_deceased: true};
    },
    sourceData() {
      return [
        {label: 'Name', value: this.meta.name},
        {label: 'Birth Place', value: this.meta.birth_place},
        {label: 'Internal Notes', value: this.meta.internal_notes},
        {label: 'Transcription', value: this.meta.transcription},
      ];
    },
  },
  methods: {
    beforeOpen() {
      if (isEmpty(this.familyTreePersonOptionsState)) {
        this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
      }
    },
    updatePerson(data) {
      return this.$store.dispatch('updateFamilyTreePersonAction', data);
    },
    saveClickHandler(data) {
      this.updatePerson(data.person).then(response => {
        const factsPromise =
          data.facts && data.facts.length
            ? this.$store.dispatch('updateFamilyTreePersonFactsAction', {
                personId: data.person.object_id,
                facts: data.facts,
              })
            : Promise.resolve();
        factsPromise.then(() => {
          this.$emit('update');
          this.closeModal();
        });
      });
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
  },
  components: {AdminPersonEditForm},
  name: 'PersonEditModal',
};
</script>

<style lang="scss" scoped>
.source-data {
  background-color: $background-color;
  padding: 10px;
  margin-bottom: 20px;

  .source-data-item {
    display: flex;
    align-items: baseline;
    white-space: pre-wrap;

    .label {
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
}
</style>
